import { React, useState } from 'react'
import moment from 'moment/moment'
import './CreoItem.css'
import TooltipVideo from '../Tooltip/TooltipVideo'
import file from '../../img/files.png'
import Modal from '../Modal/Modal'

const CreoItem = ({item, className, multiOptionGeo}) => {
    const date = moment(item.release_date).format("DD.MM.YYYY")
    const [modalActive, setModalActive] = useState(false)
    const [tooltipActive, setTooltipActive] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
console.log(item)

const handleMouseEnterGeo = () => {
    setShowTooltip(true)
}

  const handleMouseLeaveGeo = () => {
    setShowTooltip(false)
}

const handleCopyToClipboard = () => {
    if (item.text) {
        navigator.clipboard.writeText(item.text).then(() => {
            alert('Текст скопирован')
            // console.log("Text copied to clipboard");
        }).catch(err => {
            // console.error("Failed to copy text: ", err);
        });
    }
};


  return (
    <div className={'element ' + className}>
        <div className={'wrapCreo' + (item.available ? '' : ' opacity-50')}>
        <small className={multiOptionGeo.some(el => item.readyGeos.includes(el)) ? 'upClueFree' : 'upClue'}>{item.orderedGeos.length}</small>
            <TooltipVideo item={item} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
            <div className={'discriptionCreo'}>
                <div className={'wrapperCreo'}>
                    <div className={'appNameTest'}>{item.creoName} 
                    </div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'discription_mainTest'}>Template: {item.template}</div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'titleTest'}>Duration: {item.duration}</div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'linkTest'}>Topic: {item.hashes.join('\n')}</div>
                </div>
            </div>
            <button className={'webBtnCreo'} onClick={()=> setModalActive(true)}>Подробнее</button>
        </div>
        {modalActive && (
            <section className={'section'}>
                <Modal 
                    active={modalActive}
                    setActive={setModalActive}>
                        <div className={'popupContent'}>
                            <TooltipVideo item={item} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
                            <div className={'discriptionCreoPopup'}>
                                <div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'appNameTest'}>{item.creoName}
                                        <div 
                                onMouseEnter={handleMouseEnterGeo} 
                                onMouseLeave={handleMouseLeaveGeo}
                                onClick={handleCopyToClipboard}
                                style={{
                                    position: 'relative',
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    cursor: 'pointer' 
                                }}>
                                    <img 
                                        alt = ''
                                        className={'iconTest'} 
                                        src={file}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                    {showTooltip && item.text && (
                                        <div 
                                            className="tooltip"
                                            style={{
                                                position: 'absolute',
                                                transform: 'translate(30%, 55%)',
                                                backgroundColor: '#333',
                                                color: '#fff',
                                                padding: '5px',
                                                borderRadius: '4px',
                                                fontSize: '14px',
                                                width: '400px',
                                                whiteSpace: 'normal',
                                                overflowWrap: 'break-word',
                                                zIndex: 1000,
                                                cursor: 'pointer',
                                                pointerEvents: 'auto'
                                            }}
                                        >
                                            {item.text.split(/\s*;\s*/).map((part, index) => (
                                                <>
                                                    {part}
                                                    <br />
                                                </>
                                            ))}
                                            {/* {item.geos.split(' ').map((geo, index) => (
                                                <small key={index}style={{ marginRight: '5px' }}>
                                                    {geo}
                                                </small>
                                            ))} */}
                                        </div>
                                    )}
                            </div>
                                        </div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Template: {item.template}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'titleTest'}>Duration: {item.duration}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'linkTest'}>Topic: <br/> {item.hashes.join(' ')}</div>
                                    </div>
                                </div>
                                <div>
                                {item.differs1?.trim() && (
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>
                                            ✅ Можно поменять:
                                            <ul>
                                                {item.differs1.split('; ').filter(Boolean).map((diff, index) => (
                                                <li key={index}>{diff}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {item.differs2?.trim() && (
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>
                                            ❌ Нельзя поменять:
                                            <ul>
                                                {item.differs2.split('; ').filter(Boolean).map((diff, index) => (
                                                <li key={index}>{diff}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                </div>
                                <div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Refs: {item.refers.join(' ')}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Release: {moment(item.release_date).format("hh:mm:ss DD.MM.YYYY")}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Formats: {item.formats}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>My GEO: <br/> {item.myCreoGeos.join(' ')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>
            </section>
        )}
    </div>
  )
}

export default CreoItem
