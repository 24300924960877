import { React, useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayCreos, resetCreos } from '../../store/creosReducer'
import { addArrayFilteredCreos, resetFilteredCreos } from '../../store/filteredCreosReducer'
import { addUser } from '../../store/userReducer'
import './CreosList.css'
import Navbar from '../NavBar/NavBar'
import CreoItem from '../CreoItem/CreoItem'
import { getFreeCreos } from '../../http/creosApi'
import { getOneUser } from '../../http/usersApi'
import { getGeos } from '../../http/geosApi'
import Loader from '../Loader'


const CreosList = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const creosStore = useSelector(state => state.creos.creos)
    const filteredCreosStore = useSelector(state => state.filteredCreos.filteredCreos)
    const [loading, setLoading] = useState(true)
    const [multiOptionTemplate, setMultiOptionTemplate] = useState([])
    const [multiOptionCreoName, setMultiOptionCreoName] = useState([])
    const [multiOptionTopic, setMultiOptionTopic] = useState([])
    const [multiOptionGeo, setMultiOptionGeo] = useState([])
    const [templates, setTemplates] = useState([])
    const [creoNames, setCreoNames] = useState([])
    const [geo, setGeo] = useState([])
    const [fullGeo, setFullGeo] = useState([])
    const [hashes, setHashes] = useState([])
    // const [page, setPage] = useState(1)
    const [clickedSort1, setClickedSort1] = useState(false)
    const [clickedSort2, setClickedSort2] = useState(false)
    const [clickedSort3, setClickedSort3] = useState(false)

    // const observerTarget = useRef(null)
    
    useEffect(() => {
        // if (user) {
            //     getOneUser(user.username).then(data => {
            //         dispatch(addUser(data))
            //     })
                
            //     getFreeCreos().then(data => {
            //         if (data.length !== 0) {
            //             const creosDB = data.map(el => el._id)
            //             const check = creosStore.some(elem => creosDB.includes(elem._id))
            //             if (!check) {
            //                 dispatch(addArrayCreos(data))
            //             } 
            //         } 
            //     })
            // } else {
            //     alert('Вы не авторизованы')
            // console.log(userStore.webName)
            if (creosStore.length === 0) {
                getFreeCreos(userStore.webName).then(data => {
                    const sortedArr = data.sort((a, b) => (a.creoName > b.creoName) ? -1 : (a.creoName === b.creoName) ? ((a.order > b.order) ? -1 : 1) : 1)

                    dispatch(addArrayCreos(sortedArr))
                    setLoading(false)
                })
            }
        // }
    }, [])

    

    // useEffect(() => {
    //     if (creosStore.length === 0) {
    //         setLoading(true)
    //         getFreeCreos(userStore.webName, page, 9).then(data => {
    //             const sortedArr = data.sort((a, b) => 
    //                 (a.creoName > b.creoName) ? -1 : 
    //                 (a.creoName === b.creoName) ? ((a.order > b.order) ? -1 : 1) : 1
    //             )
    // console.log(sortedArr)
    //             dispatch(addArrayCreos(sortedArr))
    //             setLoading(false)
    //         })
    //     }
    // }, [page])

    // const loadMore = () => {
    //     setPage(prev => prev + 1)
    // }

    //  // Intersection Observer для подгрузки при скролле
    //  useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         if (entries[0].isIntersecting) {
    //             loadMore()
    //         }
    //     }, { threshold: 1.0 })
    
    //     if (observerTarget.current) {
    //         observer.observe(observerTarget.current)
    //     }
    
    //     return () => {
    //         if (observerTarget.current) {
    //             observer.unobserve(observerTarget.current)
    //         }
    //     }
    // }, [])

    useEffect(() => {
        currentFilter()
        setTimeout(() => setLoading(false), 800)
    }, [multiOptionTemplate, multiOptionCreoName, multiOptionTopic, multiOptionGeo])

    // useEffect(() => {
    //     sortItems()
    // }, [clickedSort1, clickedSort2, clickedSort3])

    useEffect(() => {
        const getOptionsFromDB = async () => {
            const getGeosFromDB = async () => {
                const data = await getGeos().then(geos => {
                    const templates = Array.from(new Set(creosStore.map(el => el.template)))
                    const modifyTemp = templates.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setTemplates(modifyTemp)

                    const creoNames = creosStore.map(el => el.creoName)
                    const modifyCreoNames = creoNames.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setCreoNames(modifyCreoNames.sort((a, b) => a.value > b.value ? 1 : -1))

                    const topics = Array.from(new Set(creosStore.map(el => el.hashes[0])))
                    const modifyTopics = topics.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setHashes(modifyTopics)

                    const modifyGeo = geos[0].geos.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setGeo(modifyGeo)
                    setFullGeo(modifyGeo)
                })
            }
            getGeosFromDB()
        }
        // if (user) {
            getOptionsFromDB()
            // setFilteredCreos(creosStore)
        // }
    }, [creosStore])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const currentFilter = () => {
        const newArr = creosStore.filter(item => {
            const newObj = 
                {
                    template: item.template,
                    creoName: item.creoName,
                    hashes: item.hashes,
                    geo: item.geo,
                    order: item.order
                }

            const multiOption = multiOptionTemplate.concat(multiOptionCreoName).concat(multiOptionTopic)
            
            if (multiOption.every(el => Object.values(newObj).flat().includes(el))) {
                if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
                    return item
                } 
            } else {
                if (multiOption.length === 0) {
                    if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
                        return item
                    }
                } else {
                    if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
                        if (multiOption.every(el => Object.values(newObj).flat().includes(el))) {
                            return item
                        }
                    } 
                }
            }
        })

        const sortedArr = newArr.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
        dispatch(addArrayFilteredCreos(sortedArr))

        // const templates = Array.from(new Set(newArr.map(el => el.template)))
        // const newTemplates = templates.map(element => {
        //     return {
        //         value: element,
        //         label: element,
        //         color: '#000'
        //     }
        // })

        // const newCreoNames = newArr.map(element => {
        //     return {
        //         value: element.creoName,
        //         label: element.creoName,
        //         color: '#000'
        //     }
        // })

        // const topics = Array.from(new Set(newArr.map(el => el.hashes[0])))
        // const newTopics = topics.map(element => {
        //     return {
        //         value: element,
        //         label: element,
        //         color: '#000'
        //     }
        // })
        // const geos = Array.from(new Set(newArr.map(el => el.geo).flat()))
        // const newGeos = geos.map(element => {
        //     return {
        //         value: element,
        //         label: element,
        //         color: '#000'
        //     }
        // })

        // setTemplates(newTemplates)
        // setCreoNames(newCreoNames.sort((a, b) => a.value > b.value ? 1 : -1))
        // setHashes(newTopics)
        
        if (multiOptionGeo.length !== 0) {
            setGeo([])
        } else {
            setGeo(fullGeo)
        }

    }

    const sortItems = (e) => {
        switch (e.target.id) {
            case '1':
                setClickedSort2(false)
                setClickedSort3(false)
                if (!clickedSort1) {
                    setClickedSort1(!clickedSort1)
                    if (filteredCreosStore.length !== 0) {
                        const data = filteredCreosStore.sort((a, b) => a.geo.length - b.geo.length)
                    } else {
                        const data = creosStore.sort((a, b) => a.geo.length - b.geo.length)
                    }
                } else {
                    setClickedSort1(!clickedSort1)
                    if (filteredCreosStore.length !== 0) {
                        const data = filteredCreosStore.sort((a, b) => b.geo.length - a.geo.length)
                    } else {
                        const data = creosStore.sort((a, b) => b.geo.length - a.geo.length)
                    }
                }
                break;
            case '2':
                setClickedSort1(false)
                setClickedSort3(false)
                if (!clickedSort2) {
                    setClickedSort2(!clickedSort2)
                    if (filteredCreosStore.length !== 0) {
                        const data = filteredCreosStore.sort((a,b) => a.creoName > b.creoName ? 1 : -1)
                    } else {
                        const data = creosStore.sort((a,b) => a.creoName > b.creoName ? 1 : -1)
                    }
                } else {
                    setClickedSort2(!clickedSort2)
                    if (filteredCreosStore.length !== 0) {
                        const data = filteredCreosStore.sort((a, b) => a.creoName > b.creoName ? -1 : 1)
                    } else {
                        const data = creosStore.sort((a, b) => a.creoName > b.creoName ? -1 : 1)
                    }
                }
                break;
            case '3':
                setClickedSort1(false)
                setClickedSort2(false)
                if (!clickedSort3) {
                    setClickedSort3(!clickedSort3)
                    if (filteredCreosStore.length !== 0) {
                        const data = filteredCreosStore.sort((a,b) => a.order > b.order ? 1 : -1)
                    } else {
                        const data = creosStore.sort((a,b) => a.order > b.order ? 1 : -1)
                    }
                } else {
                    setClickedSort3(!clickedSort3)
                    if (filteredCreosStore.length !== 0) {
                        const data = filteredCreosStore.sort((a, b) => a.order > b.order ? -1 : 1)
                    } else {
                        const data = creosStore.sort((a, b) => a.order > b.order ? -1 : 1)
                    }
                }
                break;
            case '4':
                if (filteredCreosStore.length !== 0) {
                    const data = filteredCreosStore.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
                    dispatch(resetFilteredCreos())
                    dispatch(addArrayFilteredCreos(data))
                } else {
                    const data = creosStore.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
                    dispatch(resetCreos())
                    dispatch(addArrayCreos(data))
                }
                setClickedSort1(false)
                setClickedSort2(false)
                setClickedSort3(false)
                break;
            default:
                break;
        }
    }

  return (
    // <>
    //     <Navbar />
        <div className={'content'}>
            {/* {userStore.role === 'TechHelper' ?
                <h3>Нет доступа</h3>
            :
                <> */}
                    <div className={'filters'}>
                        <span className={'text'}>
                            <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'Template'} options={templates} onChange={selectedOption => setMultiOptionTemplate(selectedOption.map(el => el.value))/*setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/^(?:[0-9]+|)(?:[0-9]+|-[0-9]+)/gm).test(item)))*/} isMulti />
                        </span>
                    {/* <span className={'text'}>
                            <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'CreoName'} options={creoNames} onChange={selectedOption => setMultiOptionCreoName(selectedOption.map(el => el.value)) setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/[C][P][0-9]+/gm).test(item))) isMulti />
                        </span> */}
                        <span className={'text'}>
                            <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'Topic'} options={hashes} onChange={selectedOption => setMultiOptionTopic(selectedOption.map(el => el.value))/*setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/(?:[A-Z]|[А-Я])(?:[a-z]+|[а-я]+)/gm).test(item)))*/} isMulti />
                        </span>
                        <span className={'text'}>
                            <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'Geo'} options={geo} onChange={selectedOption => setMultiOptionGeo(selectedOption.length !== 0 ? [...multiOptionGeo, selectedOption[0].value] : multiOptionGeo.filter(item => !(/[A-Z][A-Z]$/gm).test(item)))}/*setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/[A-Z][A-Z]/gm).test(item)))}*/ isMulti />
                        </span>
                    </div>
                    <div className={'sortTab'}>
                        <button id='1' /*disabled={clickedSort2 || clickedSort3}*/ className={clickedSort1 ? 'webBtnSort active' : 'webBtnSort'} onClick={sortItems}>По популярности</button>
                        <button id='2' /*disabled={clickedSort1 || clickedSort3}*/ className={clickedSort2 ? 'webBtnSort active' : 'webBtnSort'} onClick={sortItems}>По крео</button>
                        <button id='3' /*disabled={clickedSort1 || clickedSort2}*/ className={clickedSort3 ? 'webBtnSort active' : 'webBtnSort'} onClick={sortItems}>По шаблону</button>
                        <button id='4' className={'webBtnSort'} onClick={sortItems}>Сброс</button>
                    </div>
                    <div className={'list'}>
                        {loading ?
                            <Loader />
                        :
                        multiOptionTemplate.length !== 0 || multiOptionCreoName.length !== 0 || multiOptionTopic.length !== 0 || multiOptionGeo.length !== 0 ?
                                filteredCreosStore.map(elem => {
                                    return (
                                        <CreoItem
                                            key={elem._id}
                                            item={elem}
                                            className={'itemCreo'}
                                            multiOptionGeo={multiOptionGeo}
                                        />
                                    )   
                                })
                            :
                                creosStore.map(elem => {
                                    return (
                                        <CreoItem
                                            key={elem._id}
                                            item={elem}
                                            className={'itemCreo'}
                                            multiOptionGeo={multiOptionGeo}
                                        />
                                    )   
                                })
                        }
                        {/* <div ref={observerTarget} style={{ height: 20 }} /> Невидимый триггер */}
                    </div>
                {/* </>
            } */}
    </div>
    // </>
  )
}

export default CreosList
