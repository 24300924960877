import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import moment from 'moment/moment'
import './AppItem.css'
import TooltipImage from '../Tooltip/TooltipImage'
import android from '../../img/android.png'
import ios from '../../img/apple.png'
import globus from '../../img/globus.png'
import { updateAppByWeb } from '../../http/appsApi'
import { getAttributionLinks } from '../../http/attributionLinksApi'
import { updateApp } from '../../store/appsReducer'

const AppItem = ({item, className}) => {
    const dispatch = useDispatch()
    const [showTooltip, setShowTooltip] = useState(false)
    const date = moment(item.release_date).format("DD.MM.YYYY")
    const userStore = useSelector(state => state.user.user)
    const [showTooltipAcc, setShowTooltipAcc] = useState(false)
    const [selectedSource, setSelectedSource] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [sourceOptions, setSourceOptions] = useState([])

    // const displayedText = isExpanded ? item.geos : item.geos?.slice(0, 20) + (item.geos?.length > 20 ? '...' : '')
    
    useEffect(() => {
        const formattedSources = Object.keys(item.sources).map(source => ({
            value: source,
            label: source.charAt(0).toUpperCase() + source.slice(1)
        }))
        setSourceOptions(formattedSources)
      }, [item.sources])

      const onSendData = useCallback(() => {
        if (userStore.role !== 'TechHelper' && userStore.role !== 'CreoUnit') {
            updateAppByWeb(item._id, item.appName, userStore.webName, selectedSource).then(data => {
                data.action = 'toWork'
                if (data.message === 'Успешно') {
                    dispatch(updateApp(data.readyApp))
                    alert('Успешно')
                } else {
                    alert('Ошибка! Вы либо уже льете на источник, либо свободных слотов больше нет')
                }
            })
        } else {
            alert('Нет доступа')
        }
      })

    useEffect(() => {
        if (selectedSource !== '' || userStore.webName !== 'WebAnton') {
            setShowButton(true)
        }
    }, [selectedSource])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }

    const handleMouseEnterGeo = () => {
        setShowTooltip(true)
    }
    
      const handleMouseLeaveGeo = () => {
        setShowTooltip(false)
    }

    const handleMouseEnterAcc = () => {
        setShowTooltipAcc(true)
    }
    
      const handleMouseLeaveAcc = () => {
        setShowTooltipAcc(false)
    }
    

  return (
    <div className={'elementTest ' + className}>
        <div className={'wrapTest'}>
            <TooltipImage item={item}/>
            <div className={'discriptionAppsTest'}>
                <div className={'wrapperTest'}>
                    <div className={'appNameTest'}>{item.appName}
                        <div className={'iconWrapTest'}>
                            <img 
                                alt = ''
                                className={'iconTest'} 
                                src={item.platform === 'android' ? android : ios}
                            />
                            <div 
                                onMouseEnter={handleMouseEnterGeo} 
                                onMouseLeave={handleMouseLeaveGeo}
                                style={{ position: 'relative', display: 'inline-block' }}>
                                    <img 
                                        alt = ''
                                        className={'iconTest'} 
                                        src={globus}
                                    />
                                    {showTooltip && item.geos && (
                                        <div 
                                            className="tooltip"
                                            style={{
                                                position: 'absolute',
                                                transform: 'translateX(-50%)',
                                                backgroundColor: '#333',
                                                color: '#fff',
                                                padding: '5px',
                                                borderRadius: '4px',
                                                fontSize: '14px',
                                                maxWidth: '300px',
                                                whiteSpace: 'normal',
                                                overflowWrap: 'break-word',
                                                zIndex: 1000,
                                                cursor: 'pointer',
                                                pointerEvents: 'auto'
                                            }}
                                        >
                                            {item.geos.split(' ').map((geo, index) => (
                                                <small key={index}style={{ marginRight: '5px' }}>
                                                    {geo}
                                                </small>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className={'discription_mainTest'}>Partner ID: {item.partner}</div>
                    <div className={'title_mainTest'}>{item.age}</div>
                </div>
                <div className={'wrapperTest'}>
                    <div className={'titleTest'}>Release: {date}</div>
                    <div className={'titleTest'}>Status: {item.status}</div>
                    <div className={'linkTest'}><a className={'link_tg'} href={item.link} target="_blank" rel="noopener noreferrer">Link</a></div>
                </div>
                <div className={'wrapperTest'}>
                    <div className={'titleTest'}>Source:
                        <span className={'textTest'}>
                            <Select styles={colorStyles} className={'smallOptionsTest'} options={sourceOptions} onChange={selectedOption => setSelectedSource(selectedOption.value)} />
                        </span>
                    </div>
                </div>
                <div className={'wrapperTest'}>
                    {item.sources ?
                        // item.partner === '7' ?
                        //     Object.entries(item.sources).map(elem => {
                        //         if (elem[0] === 'ironsource' && elem[1].length === 0) {
                        //             return (
                        //                 <div className={'title'}>🟢{elem[0]}</div>
                        //             )   
                        //         } else if (elem[0] === 'ironsource' && elem[1].length !== 0) {
                        //             return (
                        //                 <div className={'title'}>🔴{elem[0]}</div>
                        //             )  
                        //         }
                        //     })
                        // :
                        Object.entries(item.sources).map(elem => {
                            const isIronSource = elem[0] === 'ironsource';
                            const isEmpty = elem[1].length === 0;
                          
                            return (
                              <div className={'titleTest'} key={elem[0]}>
                                {isEmpty ? '🟢' : '🔴'}
                                {elem[0]}
                                {isIronSource && item.appIronAcc && (
                                    <div
                                        onMouseEnter={handleMouseEnterAcc} 
                                        onMouseLeave={handleMouseLeaveAcc}
                                        style={{ position: 'relative', display: 'inline-block' }}
                                    >
                                        <small>⭐</small>
                                        {showTooltipAcc && ( 
                                            <div className={'tooltip'}
                                            style={{
                                                position: 'absolute',
                                                transform: 'translateX(-50%)',
                                                backgroundColor: '#333',
                                                color: '#fff',
                                                padding: '5px',
                                                borderRadius: '4px',
                                                fontSize: '14px',
                                                maxWidth: '300px',
                                                whiteSpace: 'normal',
                                                overflowWrap: 'break-word',
                                                zIndex: 1000,
                                                cursor: 'pointer',
                                                pointerEvents: 'auto'
                                            }}>
                                                <small>Приложение общее под IronSorce на аккаунте {item.appIronAcc}</small>
                                            </div>
                                        )}
                                    </div>
                                )}
                              </div>
                            );
                          })
                    :
                        <>
                            {item.sources.map(elem => {
                                return (
                                    <div className={'titleTest'}>🟢{elem.label}</div>
                                )
                            })}
                        </>
                    }
                </div>
            </div>
            {/* <div className={'wrapperGeosTest'}>
                {item.geos && (
                    <div className={'titleTest'}>Geos: {displayedText}
                        {item.geos.length > 20 && (
                            <button className={'btnHide'} onClick={() => setIsExpanded(!isExpanded)}>
                                {isExpanded ? 'Hide' : 'Show'}
                            </button>
                        )}
                    </div>
                )}
            </div> */}
            {showButton && (
                <button className={'webBtn'} onClick={onSendData}>Взять в работу</button>
            )}
        </div>
    </div>
  );
}

export default AppItem


// const onSendData = useCallback(() => {
//     if (userStore.role !== 'TechHelper') {
//         updateAppByWeb(item._id, item.appName, userStore.webName, selectedSource).then(data => {
//             data.action = 'toWork'
//             if (data.message === 'Успешно') {
//                 dispatch(updateApp(data.readyApp))
//                 alert('Успешно')
//             } else {
//                 alert('Ошибка! Вы либо уже льете на источник, либо свободных слотов больше нет')
//             }
//         })
//     } else {
//         alert('Нет доступа')
//     }
// }


// const onSendData = useCallback(async () => {
//     if (userStore.role !== 'TechHelper') {
//         const data = await updateAppByWeb(item._id, item.appName, userStore.webName, selectedSource);
//         data.action = 'toWork';

//         if (data.message === 'Успешно') {
//             let partnerName;
//             if (item.partner === '3') {
//                 partnerName = 'td'
//                 const namingTemplate = item.naming
//                 const links = await getAttributionLinks(selectedSource, item.platform, partnerName)
//                 console.log(links)
//                 // [
//                 //     'https://tdappsteamapi.tools/api/Unity/Click/com.gameinsig?ifa={DeviceId}&siteid={ApplicationId}&campaignName={CampaignName}&campaignId={CampaignId}&adset={Country}&creative_set_name={CreativeSetName}&ad_id={CreativeSetID}&ad_type={AdType}&channel={Channel}&clickid={DynamicParameter}', 
//                 //     'https://tdappsteamapi.tools/api/Unity/Impression/com.gameinsig?ifa={DeviceId}'
//                 // ];
//                 // const link = 'https://play.google.com/store/apps/details?id=com.badflyinte'

//                 // Извлекаем bundle из ссылки на приложение
//                 const bundleMatch = item.link.match(/id=([\w.]+)/)
//                 const bundle = bundleMatch ? bundleMatch[1] : null

//                 if (!bundle) {
//                     alert('Не удалось извлечь bundle из ссылки на приложение.')
//                     return;
//                 }

//                 // Очищаем пробелы из item.appName для sub2
//                 const appName = item.appName.replace(/\s+/g, '')

//                 // Запрашиваем значения для sub2, sub3, sub4, sub5, sub6, acc
//                 const sub2 = appName; // sub2 будет взят из item.appName
//                 const sub3 = userStore.webName; // sub3 — из userStore.webName
//                 const sub4 = prompt('Введите значение для sub4 (ID запуска):', 'IDзапуска')
//                 const sub5 = prompt('Введите значение для sub5 (Аккаунт):', 'Аккаунт')
//                 const sub6 = selectedSource === 'ironsource' 
//                     ? '{CreativeSetName}-{Channel}' 
//                     : prompt('Введите значение для sub6 (Креатив):', 'Креатив')
//                 const acc = prompt('Введите значение для acc (Advertiser ID):', 'ID');

//                 // Формирование finalNaming
//                 let naming = namingTemplate
//                     .replace('{sub2}', encodeURIComponent(sub2))
//                     .replace('{sub3}', encodeURIComponent(sub3))
//                     .replace('{sub4}', encodeURIComponent(sub4))
//                     .replace('{sub5}', encodeURIComponent(sub5))
//                     .replace('{sub6}', sub6) // Подставляем sub6 без encodeURIComponent
//                     .replace('{acc}', encodeURIComponent(acc))

//                 // Декодируем ссылки и заменяем параметры
//                 const encodeSpecialChars = (str) => str.replace(/&/g, '%26').replace(/=/g, '%3D')

//                 const updatedLinks = links.map(link => {
//                     let decodedLink = decodeURIComponent(link) // Декодируем ссылку
//                     decodedLink = decodedLink.replace('{CampaignName}', encodeSpecialChars(naming))

//                     // Заменяем bundle
//                     decodedLink = decodedLink.replace(/com\.[a-zA-Z0-9]+/, bundle)

//                     return decodedLink
//                 })

//                 // Создаём текст со ссылками
//                 const linksString = updatedLinks.join('\n\n');

//                 // Копируем текст в буфер обмена
//                 try {
//                     await navigator.clipboard.writeText(linksString)
//                     alert('Ссылки скопированы в буфер обмена!\n\n' + linksString)
//                 } catch (error) {
//                     console.error('Ошибка копирования в буфер обмена:', error)
//                     alert('Не удалось автоматически скопировать ссылки. Скопируйте вручную:\n\n' + linksString);
//                 }
//             } else {
//                 alert('Для этого партнёра используется другой нейминг.')
//             }
//         } else {
//             alert('Ошибка! Вы либо уже льете на источник, либо свободных слотов больше нет')
//         }
//     } else {
//         alert('Нет доступа')
//     }  
// }, [selectedSource])