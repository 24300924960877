import { LOGIN_ROUTE,
         NO_ACCESS_ROUTE,
         CREOSLIST_ROUTE, 
         APPS_ROUTE, 
         HOME_ROUTE, 
         ADD_APP_ROUTE,
         UPDATE_APP_ROUTE,
         ADD_CREO_ROUTE,
         ADD_INTERACTIVE_ROUTE,
         STATS_ROUTE,
         MOVING_ROUTE,
         DELETE_APP_ROUTE,
         ADMIN_ROUTE,
         MYCREOS_ROUTE,
         READYCREOS_ROUTE,
         MYAPPS_ROUTE,
         INTERACTIVES_ROUTE,
         GEOPOOL_ROUTE,
         CREO_TEMPLATES_ROUTE, 
         CREO_PROFITS_ROUTE,
         CREO_INFO_ROUTE } from '../utils/consts'

import Auth from './Auth/Auth'
import creosList from './CreosList/CreosList'
import AppsList from './AppsList/AppsList'
import Home from './Home/Home'
import Form from './Form/Form'
import UpdateAppForm from './Form/UpdateAppForm'
import Creo from './Form/Creo'
import CreosInfo from './CreosInfo/CreosInfo'
// import Interactive from './Form/Interactive'
import Statistics from './Statistics/Statistics'
import MoveApp from './Form/MoveForm'
import DeleteApp from './Form/DeleteForm'
import Admin from './Form/AdminForm'
import MyCreos from './MyCreos/MyCreos'
import ReadyCreos from './ReadyCreos/ReadyCreos'
import MyApps from './MyApps/MyApps'
// import InteractivesList from './InteractivesList/InteractivesList'
import GeoPool from './GeoPool/GeoPool'
import CreoTemplates from './CreoTemplates/CreoTemplates'
import CreoProfits from './CreoProfits/CreoProfits'
import NoAccess from './NoAccess/NoAccess'


export const authRoutes = [
   {
      path: APPS_ROUTE,
      Component: AppsList,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead', 'CreoUnit', 'TechHelper']
   },

   {
      path: CREOSLIST_ROUTE,
      Component: creosList,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead', 'CreoUnit', 'CreoUnitHelper']
   },

   {
      path: HOME_ROUTE,
      Component: Home,
      allowedRoles: ['Admin']
   },

   {
      path: ADD_APP_ROUTE,
      Component: Form,
      allowedRoles: ['Admin', 'TechHelper']
   },

   {
      path: UPDATE_APP_ROUTE,
      Component: UpdateAppForm,
      allowedRoles: ['Admin', 'TechHelper']
   },

   {
      path: ADD_CREO_ROUTE,
      Component: Creo,
      allowedRoles: ['Admin', 'CreoLead', 'CreoUnitHelper']
   },

   {
      path: CREO_INFO_ROUTE,
      Component: CreosInfo,
      allowedRoles: ['Admin', 'CreoLead', 'CreoUnit']
   },

   {
      path: STATS_ROUTE,
      Component: Statistics,
      allowedRoles: ['Admin', 'Lead', 'CreoLead', 'TechHelper']
   },

   {
      path: MOVING_ROUTE,
      Component: MoveApp,
      allowedRoles: ['Admin', 'TechHelper']
   },

   {
      path: DELETE_APP_ROUTE,
      Component: DeleteApp,
      allowedRoles: ['Admin', 'TechHelper']
   },

   {
      path: ADMIN_ROUTE,
      Component: Admin,
      allowedRoles: ['Admin']
   },

   {
      path: MYCREOS_ROUTE,
      Component: MyCreos,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead']
   },

   {
      path: READYCREOS_ROUTE,
      Component: ReadyCreos,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead', 'CreoUnit']
   },

   {
      path: MYAPPS_ROUTE,
      Component: MyApps,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead']
   },

   // {
   //    path: INTERACTIVES_ROUTE,
   //    Component: InteractivesList
   // },

   // {
   //    path: ADD_INTERACTIVE_ROUTE,
   //    Component: Interactive
   // },

   {
      path: GEOPOOL_ROUTE,
      Component: GeoPool,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead', 'CreoUnit']
   },

   {
      path: CREO_TEMPLATES_ROUTE,
      Component: CreoTemplates,
      allowedRoles: ['Admin', 'User', 'Lead', 'CreoLead', 'CreoUnit']
   },

   {
      path: CREO_PROFITS_ROUTE,
      Component: CreoProfits,
      allowedRoles: ['Admin', 'CreoLead', 'Lead']
   }
]


export const allRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
      path: NO_ACCESS_ROUTE,
      Component: NoAccess
    }
]