import {$authHost} from './index.js'

export const addNewCreo = async (creoName, template, hashes, refers, duration, formats, text, file, differs1, differs2) => {
  const formData = new FormData();
  formData.append('creoName', creoName);
  formData.append('template', template);
  formData.append('hashes', hashes);
  formData.append('refers', refers);
  formData.append('duration', duration);
  formData.append('formats', formats);
  formData.append('text', text);
  formData.append('file', file);
  formData.append('differs1', differs1);
  formData.append('differs2', differs2);
  const {data} = await $authHost.post('/api/creo/addNewCreo', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export const getFreeCreos = async (web) => {
  const {data} = await $authHost.get('/api/creo/freeCreos/' + web)
  return data
}

// export const getFreeCreos = async (web, page, limit = 9) => {
//   const {data} = await $authHost.get(`/api/creo/freeCreos/${web}/${page}/${limit}`)
//   return data
// }

export const getCreosWithoutMyCreos = async (web) => {
  const {data} = await $authHost.get('/api/creo/freeCreosWithoutMyCreos')
  return data
}

export const getAllCreos = async () => {
  const {data} = await $authHost.get('/api/creo/allCreos')
  return data
}