import { jwtDecode } from 'jwt-decode'

import {$host, $authHost} from './index.js'

export const login = async (username, password) => {
    const {data} = await $host.post('api/users/loginTest', {username, password})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token), data
}

export const check = async () => {
    const {data} = await $authHost.get('/api/users/auth')
    localStorage.setItem('token', data.token)
    console.log(data) 
    return jwtDecode(data.token)
}

export const getUsers = async () => {
    const {data} = await $authHost.get('/api/users/getUsers')
    return data
}

export const getOneUser = async (username) => {
    const {data} = await $host.get('/api/users/getOneUser/' + username)
    return data
}

// export const getOnlyUsers = async () => {
//     const {data} = await $host.get('/api/users/getUsersWithoutTechChat')
//     return data
// } 